import { memo } from 'react';

import image from '../img/new72.jpg'
function Main () {
    return (
        <div className='w-full h-dvh '>
            <img className='w-full ' src={image} alt="image" />
        </div>
    )
}

export default memo(Main)