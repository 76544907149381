import Counter from "../Aos/Counter";


export default function Modal({title , count, icon : Icon }) {

    return (
        <div className="relative w-36 lg:w-44 m-0  lg:m-2 p-2 lg:px-3 flex flex-col rounded-md shadow-lg lg:shadow-xl border-2  bg-white">
            <div>
                <span className="text-customRed font-bold text-4xl">
                    <Counter targetNumber={count}  duration={1000}/>
                </span>
                <p className="my-3 border-b-4 border-gray-500 w-9"></p>
            </div>
            <div>
                <p className="text-sm font-bold text-gray-600 ">{title}</p>  
            </div>
            <div className="absolute bottom-0 right-0 p-1 text-gray-300">
            <p className="text-4xl"><Icon/></p>
            </div>
        </div>
    )
}