import React, { useState , useEffect } from 'react';
import Connect_image from '../img/contactus.jpg';
import '../css/connect.css';
import MapComponent from "../components/MapComponent";
import { memo } from 'react';
// react-toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Connect = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const errors = [] ;
    const handleSendMessage = (e) => {
        e.preventDefault();

        if (!name) {
            errors.push ('Please enter your name.') ; 
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            errors.push('Please enter a valid email address.');
        }
        
        if (!/^[\d\s\-\+]+$/.test(phone)) {
            errors.push('Please enter a valid phone number (digits, spaces, dashes, and plus signs only).');
        }
        
        if (!message) {
            errors.push('Please enter your message.');
        }


        if(errors.length > 0) {
            setStatus(
                errors.map((error, index) => (
                    <p key={index} style={{ color: 'red', marginBottom : '10px' }}>
                        {+index+1 +' - ' +error}
                    </p>
                ))
            )
            
            return ;
        }
            
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('message', message);
        
        fetch('./send_email.php', {
            method: 'POST',
            body: formData,
        })
        .then((response) => response.text())
        .then((data) => {
            setStatus('');
            showToast();
            ClearData() ;
        })
        .catch((error) => {
            setStatus('Error: ' + error.message);
        });
    };
    function showToast() {
        toast.success("Message sent successfully!");
    }
    function ClearData() {
        setName('') ;
        setEmail('') ;
        setPhone('') ;
        setMessage('') ;
    }

    return (
        <>
            <div className="relative w-full h-96">
                <img className="w-full h-full object-cover" src={Connect_image} alt="Contact Us" />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
                    <p className="text-white text-2xl lg:text-6xl font-extrabold" data-aos='zoom-in' data-aos-anchor-placement="top-bottom">CONTACT US</p>
                </div>
            </div>
            <div className="p-2 lg:px-28 flex-col lg:flex lg:flex-row lg:justify-between w-full">
                {/* Contact */}
                <div className="p-3 w-full lg:w-1/3 border-2 border-gray-200 rounded-sm" data-aos='zoom-in' data-aos-anchor-placement="top-bottom">
                    <form onSubmit={handleSendMessage}>
                        <div className="flex-col shadow-xl p-3 px-5 rounded-md">
                            <div className="text-center p-5">
                                <h2 className="font-extrabold text-2xl italic text-customBlue">Contact Us</h2>
                            </div>
                            <label className="bg-slate-100 w-full">
                                <p className="font-bold p-2">Full Name</p>
                                <input
                                    className="bg-gray-50 p-2 rounded-sm mb-4 w-full outline-none border-none"
                                    placeholder="Your Name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </label>
                            <label className="bg-slate-100">
                                <p className="font-bold p-2">E-Mail</p>
                                <input
                                    className="bg-gray-50 p-2 rounded-sm mb-4 w-full outline-none border-none"
                                    placeholder="Your Email Address"
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </label>
                            <label className="bg-slate-100">
                                <p className="font-bold p-2">Phone Number</p>
                                <input
                                    className="bg-gray-50 p-2 rounded-sm mb-4 w-full outline-none border-none"
                                    placeholder="Your Phone Number"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </label>
                            <label className="bg-slate-100">
                                <p className="font-bold p-2">Message</p>
                                <textarea
                                    className="bg-gray-50 p-2 rounded-sm w-full outline-none border-none"
                                    placeholder="Write your inquiry here"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </label>
                            <button
                                type="submit"
                                className="bg-green-800 py-2 px-4 text-white my-5 rounded-sm shadow-md font-bold"
                            >
                                Send Message
                            </button>
                            {status && 
                            <div className="mt-4 text-left p-2 bg-red-100 border-2 border-red-300">
                                {status}
                            </div>
                            }
                        </div>
                    </form>
                </div>
                {/* Sales & Marketing */}
                <div className="p-3 flex-col w-full border-2 border-gray-200 rounded-sm">
                    <div className='text-center my-5 shadow-lg border-2 border-orange-500 rounded-full'>
                        <h4 className='font-extrabold text-2xl lg:text-4xl italic'>Hot line</h4>
                        <p className=' text-2xl lg:text-4xl italic font-bold '>16673</p>
                    </div>
                    <div className="flex justify-around flex-wrap gap-5 lg:gap-10 mb-5" data-aos='zoom-in' data-aos-anchor-placement="top-bottom">
                        <div className="border-slate-300 border-2 rounded-md w-96 shadow-md">
                            <div className="bg-gray-100 px-2 py-1 border-b-2 border-slate-300 rounded-t-md font-bold">
                                Sales
                            </div>
                            <div className="px-2 py-1">
                                <p><strong className="text-slate-950">Phone:</strong> 01032883588</p>
                                <p><strong className="text-slate-950">Email:</strong> sales@tasheedegypt.com</p>
                            </div>
                        </div>
                        <div className="border-slate-300 border-2 rounded-md w-96 shadow-md">
                            <div className="bg-gray-100 px-2 py-1 border-b-2 border-slate-300 rounded-t-md font-bold">
                                Marketing Department
                            </div>
                            <div className="px-2 py-1">
                                <p><strong className="text-slate-950">Phone:</strong> 01021029050</p>
                                <p><strong className="text-slate-950">Email:</strong> kamal@tasheedegypt.com</p>
                            </div>
                        </div>
                    </div>
                    {/* Address */}
                    <div className="flex justify-around flex-wrap gap-5 lg:gap-10" data-aos='zoom-in' data-aos-anchor-placement="top-bottom">
                        <div className="border-slate-300 border-2 rounded-md w-96 shadow-md">
                            <div className="bg-gray-100 px-2 py-1 border-b-2 border-slate-300 rounded-t-md font-bold">
                                Alexandria office
                            </div>
                            <div className="px-2 py-1">
                                <p><strong className="text-slate-950">Address:</strong> front of green plaza</p>
                                <p><strong className="text-slate-950">Phone:</strong> 01009090806</p>
                                <p><strong className="text-slate-950">Email:</strong> m.zein@tasheedegypt.com</p>
                            </div>
                        </div>
                        <div className="border-slate-300 border-2 rounded-md w-96 shadow-md">
                            <div className="bg-gray-100 px-2 py-1 border-b-2 border-slate-300 rounded-t-md font-bold">
                                Kafr dawar office
                            </div>
                            <div className="px-2 py-1">
                                <p><strong className="text-slate-950">Address:</strong> the new entrance, Qasr elkhaleg tower, above fathalla market –kafr eldawar, elbehira</p>
                                <p><strong className="text-slate-950">Phone:</strong> 01010310133</p>
                                <p><strong className="text-slate-950">Email:</strong> tasheed@tasheedegypt.com</p>
                            </div>
                        </div>
                    </div>
                    {/* Location */}
                    <div className="p-3 lg:px-28 rounded-lg overflow-hidden pt-10" data-aos='zoom-in' data-aos-anchor-placement="top-bottom">
                        <MapComponent />
                    </div>
                </div>
            </div>
            <ToastContainer 
                position="bottom-left"  // تحديد موضع الرسالة
                autoClose={5000}        // إغلاق الرسالة تلقائياً بعد 5 ثوانٍ
                hideProgressBar={false} // عرض شريط التقدم
                newestOnTop={false}     // الرسائل الجديدة تظهر في الأعلى
                closeOnClick            // إغلاق الرسالة عند النقر عليها
                rtl={false}             // النص غير معكوس (من اليسار لليمين)
                pauseOnFocusLoss        // إيقاف المؤقت عند فقدان التركيز
                draggable               // السماح بسحب الرسالة
                pauseOnHover            // إيقاف المؤقت عند مرور المؤشر فوق الرسالة
            />
        </>
    );
}

export default memo(Connect);
