
import imageUrl from '../img/our-work.png' ;
import imageVision from '../img/T6D2jdxTwnLs.jpg' ;
import imageMission from '../img/Township.jpg' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import Title from '../components/Title';
const styleBefore = {
    content: "\f10e",
    fontSize: '2rem',
    color: '#203947',
    position: 'absolute',
    right: '2rem',
    bottom: '-0.9rem',
    display: 'inline-block',
    textShadow: '-6px -4px 0px white, -6px 11px 0px white, 6px -4px 0px white, 6px 11px 0px white'
}
const styleAfter = {
    content: "\f10e",
    fontSize: '2rem',
    color: '#203947',
    position: 'absolute',
    left: '2rem',
    top: '-1.5rem',
    display: 'inline-block',
    textShadow: '-6px -4px 0px white, -6px 11px 0px white, 6px -4px 0px white, 6px 11px 0px white'
}
export default function OurWork() {
    return (
        <div>
            <div className="relative w-full">
                <img className="w-full h-full object-cover" src={imageUrl} alt="What We Do" />
                <div className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center">
                    <p className="text-white text-2xl lg:text-6xl" data-aos='zoom-in' data-aos-anchor-placement="top-bottom">What We Do</p>
                </div>
            </div>

            <div className='mt-3 p-4 lg:px-48 text-customBlue'>
                <div className='bg-gray-100 p-4 lg:p-10 rounded-2xl relative shadow-md' data-aos='zoom-in' data-aos-anchor-placement="top-bottom">
                <p style={styleAfter}><FontAwesomeIcon icon={faQuoteLeft} /></p>
                    <strong>TASHEED DEVELOPMENTS </strong> is an Egyptian real estate corporate worked in various residential and commercial projects in Alexandria, Behira, Cairo and north coast established at 2010. <strong>TASHEED DEVELOPMENTS </strong> is one of Behira's most well recognized luxury real-estate brands known for its superior designs, classy, sophisticated interiors, global landscape architecture, and attention to details across its developments.
                <p style={styleBefore} className='absolute bottom-0 right-4 text-red-600 text-xl'><FontAwesomeIcon icon={faQuoteRight} /></p>
                </div>
            </div>
            {/* VISION */}
            <div className='p-4 lg:px-48 mt-5'>
                <Title name="VISION"/>
                <div className='flex flex-wrap mt-3 justify-between items-center border-2 p-2'>
                    <div className='w-full lg:w-1/2 p-2'>
                        <p className='text-customBlue' data-aos='zoom-in' data-aos-anchor-placement="top-bottom">
                        The satisfaction of our customer is the main priority of <strong>TASHEED DEVELOPMENTS</strong> ,  our goal is to be a point of  reference  in everything related to real-estate . setting ourselves apart to make the real-estate buying and selling process as cost effective as possible while maintaining the highest level of service along with providing accurate and up-to-date information , skilled analysis and sound real-estate advice and continually explore new ideas and technology to make selling and buying process faster , less costly and easier.
                        </p>
                    </div>
                    <div className='w-full lg:w-1/2 flex lg:justify-end'>
                        <img src={imageVision} alt="" />
                    </div>
                </div>
            </div>
             {/* MISSION */}
            <div className='p-4 lg:px-48 mt-5'>
                <Title name="MISSION"/>
                <div className='flex flex-wrap mt-3 justify-between items-center border-2 p-2'>
                    <div className='w-full lg:w-1/2 flex justify-start'>
                        <img src={imageMission} alt="" />
                    </div>
                    <div className='w-full lg:w-1/2 p-2'>
                        <p className='text-customBlue' data-aos='zoom-in' data-aos-anchor-placement="top-bottom">
                        To be the region’s most influential developer that delivers premium quality projects with highest return on investment for our shareholders and clients, providing the best work environment for our employees and we aspire else to be the prime real estate developer offering innovative solutions focused on serving our clients, acting as a trusted partner with our stakeholders and adding value to our community.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}