import React from 'react';

import 'aos/dist/aos.css';


const Card = ({ title, description, icon: Icon }) => {
    return (
      <div data-aos='zoom-in' data-aos-anchor-placement="top-bottom"  className="bg-gray-50 flex flex-col justify-start items-center gap-1 md-gap-2 w-36 md:w-64 text-center  drop-shadow-2xl p-3 my-1 rounded-xl duration-500 ease-in-out cursor-pointer">
        <div className="my-3 rounded-full border-2 border-orange-700 p-3 text-3xl text-orange-700">
          <Icon />
        </div>
        <h5 className="text-orange-900 font-bold leading-tight text-xs md:text-sm">
          {title}
        </h5>
        <h6 className="text-font_blue text-xs md:text-sm">
          {description}
        </h6>
      </div>
    );
  }
  
  export default Card;