

import image from '../img/logo-2.jpg'

export default function Logo (props) {
    return (
        <div className='w-10 md:w-16 flex justify-start'>
            <img className=' bg-gray-50  border-r-orange-700 border-4 rounded-s-xl w-14' src={image} alt=""/>
            <div className='flex flex-col p-2 font-bold'>
                <h1 className="text-sm lg:text-lg bg-gradient-to-r from-orange-500 to-pink-500 bg-clip-text text-transparent font-extrabold">TASHEED</h1>
                <h2 className='text-orange-900 text-xs lg:text-sm'>DEVELOPMENT</h2>
            </div>  
        </div>
    )
}