
import Icon_url from '../img/360.png'
import { memo } from 'react';
import { FaGlobe } from 'react-icons/fa';
import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';

function Panorama () {
    return (
        <div className='p-4 md:px-28 lg:px-80 rounded-md'>
            <div className='relative'>
                <iframe className='rounded-md w-full h-52 lg:h-96'
                src="/3D/index.html" 
                title="3D View"
                allowFullScreen
                ></iframe>
                <div className='flex gap-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 opacity-40 text-slate-300'>
                <img className='w-10 lg:w-14 opacity-70' src={Icon_url} alt="" />
                </div>
            </div>
        </div>
    )
}

export default memo(Panorama)