import { memo } from "react" ;
import image from '../img/000.jpg' ;
import image_logo from '../img/icon-white.png' ;
import image_logo2 from '../img/icon-logo.png' ;
import img from '../img/00-02.jpg' ;
import img2 from '../img/upark49.jpg' ;
import Modal from "../components/Modal";

import imageUrl from '../img/partners.png';
import  Card  from "../components/Card";
import { FaLock } from 'react-icons/fa';
import { FaWalking } from 'react-icons/fa';
import { FaUserSecret } from 'react-icons/fa';

// icons
import { FaTools } from 'react-icons/fa' ;
import { MdVilla } from 'react-icons/md';
import { FaTree } from 'react-icons/fa';
import { FaDoorOpen } from 'react-icons/fa';
import { MdHouseSiding } from 'react-icons/md';
import { MdLocalParking } from 'react-icons/md';
import { MdDashboard } from 'react-icons/md';
import { MdWork } from 'react-icons/md';
import { MdAir } from 'react-icons/md';
import { MdStore } from 'react-icons/md';
const containerStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    background: '5px solid rgba(255, 255, 255, 0.9)', 
    borderTop: '2px solid rgba(255, 255, 255, 0.1)', 
    display : 'flex' ,
    flexWrap: 'wrap', 
    gap: '10px'  ,
    justifyContent: 'space-evenly' ,
    padding : '10px 0' ,
};

function Muruj() {

    return (
        <>
        <div className="relative w-full lg:h-dvh ">
            <img className="w-full lg:h-dvh object-cover" src={image} alt="" />
            <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center">
                <img className="absolute top-10 w-32 lg:w-64" src={image_logo} alt=""  data-aos='zoom-in' data-aos-anchor-placement="top-bottom"/>
            </div>
            <div className="lg:absolute lg:bottom-0 w-full">
            <div style={containerStyle} data-aos='zoom-in' data-aos-anchor-placement="top-bottom" >
                <Modal title='ENTRANCE' count={4} icon={FaDoorOpen}/>
                <Modal title='UNIT' count={120} icon={MdHouseSiding}/>
                <Modal title='OFFICE' count={28} icon={MdWork}/>
                <Modal title='PARKING' count={587} icon={MdLocalParking}/>
                <Modal title='VILLA' count={4} icon={MdVilla}/>
                <Modal title='COMMERCIAL' count={10} icon={MdStore}/>
                <Modal title='FLOOR' count={13} icon={MdDashboard}/>
            </div>
            </div>

        </div>
        
        <div className="px-5 py-2 lg:px-48 bg-gray-100" >
            <div className="lg:flex lg:justify-items-center mt-5" data-aos='zoom-in' data-aos-anchor-placement="top-bottom" >
                <div className="w-full lg:w-1/2 lg:h-96 lg:p-16">
                <p className="text-customBlue">One of <strong>Tasheed</strong> developments new real estate compounds, Located in Smouha, Alexandria, Egypt. Built around the concept of new urbanism which emphasizes self-efficiency, holistic living and strong community ties, designed with the aim to appreciate the green areas, landscapes, ventilation and social communication in a high quality modern way.</p>
                </div>
                <div className="w-full lg:w-1/2 mt-2">
                    <img className=" rounded-lg" src={img} alt="" />
                </div>
            </div>
        </div>

        <div style={containerStyle} >
            <Card
                title="FULLY SERVICED"
                icon={FaTools}
            />
            <Card
                title="VENTILATION"
                icon={MdAir}
            />
            <Card
                title="PARKS"
                icon={FaTree}
            />
            <Card
                title="PRIVACY"
                icon={FaUserSecret}
            />
            <Card
                title="WALKWAY"
                icon={FaWalking}
            />
            <Card
                title="CLOSED COMMUNITY"
                icon={FaLock}
            />
        </div>

        <div className="p-4 lg:px-48 flex flex-wrap">
            <div className="lg:w-1/2" data-aos='zoom-in' data-aos-anchor-placement="top-bottom" >
                <ul className="list-disc pl-5">
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Ground Floor</span>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Project Area 4580</span>
                        <sup className="text-red-900">M2</sup>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Building Area 2920 </span><sup className="text-red-900">M2</sup>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">13 Repeated Floor</span>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Parking Area 1660 </span>
                        <sup className="text-red-900">M2</sup>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Four Entrance gates blocks</span>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Parking Areas ( Hydraulic & Individual)</span>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Underground Garage</span>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Service Floor</span>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Units 146 M to 170</span>
                        <sup className="text-red-900">M2</sup>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Clubhouse</span>
                    </li>
                    <li className="mb-2 flex items-center">
                        <span className="w-3 h-3 bg-customRed rounded-sm inline-block mr-2"></span>
                        <span className="text-customBlue">Gym</span>
                    </li>
                    
                </ul>
            </div>
            <div className="lg:w-1/2 flex justify-center items-center" data-aos='zoom-in' data-aos-anchor-placement="top-bottom" >
                <img className="w-full" src={image_logo2} alt="" />
            </div>
        </div>

        <div className="px-5 py-2 lg:px-48 bg-gray-100 mt-5">
            <div className="lg:flex lg:justify-items-center mt-5" data-aos='zoom-in' data-aos-anchor-placement="top-bottom" >
                <div className="w-full lg:w-1/2 mt-2">
                    <img className=" rounded-lg" src={img2} alt="" />
                </div>
                <div className="w-full lg:w-1/2 lg:h-96 lg:p-16">
                <p className="text-customBlue"><strong>Upark</strong> compound located in the heart of <strong>Muruj city</strong>, is surrounded with a landscape and has 4 different entrances each is equipped with the best security and guard services around the clock, to provide all needed security and safety factors to the compound residents.</p>
                </div>
            </div>
        </div>

        </>
    )
}

export default memo(Muruj)