
import { useState , useEffect } from "react"

const Counter = ({targetNumber , duration}) => {
    const [count , setCount] = useState(0) ;
    useEffect(() => {
        if(count < targetNumber) {
            const interval = setInterval(() => {
                setCount(prevCount => prevCount + 1) ;
            }, duration / targetNumber) ;
            return () => clearInterval(interval) ;
        }
    } , [count , targetNumber , duration]) ;

    return (
        count
    )
}

export default Counter ;