
import  { useEffect } from 'react';
import AOS from 'aos';

import TopNav from "./TopNav"
import Header from "./Header"

// pages 
import Home from "./pages/Home" ;
import OurWork from "./pages/OurWork" ;
import Footer from "./pages/Footer";
import Connect from './pages/Connect' ;
import P404 from './pages/P404';
import Upark from './pages/Upark';
import Muruj from './pages/Muruj';
//components


// react-toastify
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Route , Routes } from "react-router-dom" ;
import ScrollBar from './components/ScrollBar';
import Careers from './pages/Careers';
import Social from './pages/Social';




export default function App() {
    useEffect(() => {
        AOS.init({
            duration: 3000, // مدة المؤثرات بالألفي ثانية
            offset: 200, // المسافة قبل تفعيل المؤثر
            once: true, 
        });
    }, []);
    return (
        <>
            
            <Header/>
            
            <TopNav/>

            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="our-work" element={<OurWork/>}/>
                <Route path="Connect" element={<Connect/>}/>
                <Route path="Careers" element={<Careers/>}/>
                <Route path="social-responsibility" element={<Social/>}/>
                <Route path="upark" element={<Upark/>}/>
                <Route path="Muruj" element={<Muruj/>}/>
                <Route path="/*" element={<P404/>}/>
            </Routes>
            
            <Footer/>
            <ScrollBar/>
            
            
        </>
    )
}